import type { Theme } from '../../model'

export const darkTheme: Theme = {
  colors: {
    // основная тема
    primary: '#1b65f5',
    secondary: '#233353',
    error: '#FF3b30',
    success: '#32d74b',
    dark: '#000',
    light: '#fff',

    text: {
      main: '#233353',
      primary: '#1B65F5',
      secondary: '#727985',
      quaternary: '#C1C7D1'
    },

    calendar: {
      text: {
        inactive: '#D2D9E6',
        primary: '#233353',
        weekend: '#EF413D',
        active: '#1B65F5'
      }
    },

    checkbox: {
      icon: '#fff'
    }
  },
  backgroundColor: {
    primary: '#FFF',
    overlay: 'rgba(35,51,83,0.6)',
    error: '#FF3b30',
    success: '#32d74b',
    dark: '#000',
    light: '#fff',

    checkbox: {
      DEFAULT: '#1B65F5',
      disabled: '#727985'
    },
    calendar: {
      range: '#F5F7FF',
      active: '#1B65F5'
    },
    alert: {
      success: '#ddffe9',
      error: '#FFE7E6'
    }
  },
  borderColor: {
    error: '#FF3b30',
    success: '#32d74b',
    dark: '#000',
    light: '#fff',
    checkbox: {
      DEFAULT: '#1B65F5',
      disabled: '#727985'
    },
    alert: {
      success: '#ddffe9',
      error: '#FFE7E6'
    }
  },
  //TODO
  boxShadow: {
    // badge-main
    // badge-secondary
    // модальные окна
    // поисковая форма
    tertiary: '0 4px 6px 0px rgba(119,119,119,0.05)',
    // каретки слайдера
    quaternary: '0 4px 12px 0px rgba(27,101,245,0.4)',
    fivefold: '-4px 0px 8px 0px rgba(177,186,204,0.16)',
    sixfold: '0px 3px 3px 0px rgba(0,0,0,0.02)',
    poster_primary: '0 8px 20px 0px rgba(35,51,83,0.2)',
    card_primary: '0px 4px 4px 0px rgba(171,179,186,0.13)',
    card_primary_hover: '0px 4px 12px 0px rgba(171,179,186,0.20)',
    compilations_preview: '-4px 0 4px 0 rgba(35,51,81,0.14)'
  },
  fontFamily: {
    sans: 'Inter',
    playfair: 'Playfair'
  }
}
