import type { Category } from '@/5_entities/Category'

export interface Pagination {
  totalCount: number
  pageSize: number
  pageIndex: number
  currentPage: number
  pagesCount: number
  offset: number
}

export interface SearchResultItem<T, R> {
  type: T
  object: R
}

export interface SearchResult<ObjectType, ObjectModel> {
  pagination: Pagination
  items: SearchResultItem<ObjectType, ObjectModel>[]
}

export enum SearchSorting {
  nearest = 'nearestFirst',
  cheapest = 'cheapestFirst',
  expensive = 'expensiveFirst',
  popular = 'popularFirst'
}

export type SearchSortingTitle = 'ближайшие' | 'сначала дешевле' | 'сначала дороже' | 'популярные'
export const SearchSortingMap: Record<SearchSorting, SearchSortingTitle> = {
  nearestFirst: 'ближайшие',
  cheapestFirst: 'сначала дешевле',
  expensiveFirst: 'сначала дороже',
  popularFirst: 'популярные'
}

export interface BaseSearchFilters {
  pagination: Pick<Pagination, 'pageSize' | 'currentPage'>
  filter: {
    fromDateTime?: string
    tillDateTime?: string
    textQuery?: string
    fromPrice?: number
    tillPrice?: number
    categoryIds?: Category['id'][]
  }
  sorting?: SearchSorting
}
