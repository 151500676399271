import { useRouter } from 'vue-router'
import { AbortError, type ErrorResponse } from '../error'

export const useHandlePageError = () => {
  const router = useRouter()

  const handlePageError = (error: ErrorResponse | any) => {
    if (error instanceof AbortError || error?.name === 'AbortError') return

    const code = error.code || error.status

    if (!code) return

    if (code === 404) {
      return router.replace('/404')
    }

    if (code === 500) {
      return router.replace('/500')
    }
  }

  return {
    handlePageError
  }
}
