import type { Theme } from '../../model'

export const defaultTheme: Theme = {
  colors: {
    // основная тема
    primary: '#1B65F5',
    secondary: '#233353',
    error: '#FF3b30',
    success: '#32d74b',
    dark: '#000',
    light: '#fff',

    text: {
      main: '#233353',
      primary: '#1B65F5',
      secondary: '#727985',
      tertiary: '#B1BACC',
      quaternary: '#C1C7D1',
      fivefold: '#053697'
    },

    calendar: {
      text: {
        inactive: '#D2D9E6',
        primary: '#233353',
        weekend: '#EF413D',
        active: '#1B65F5'
      }
    },

    button: {
      primary: {
        DEFAULT: '#233353',
        hover: '#233353',
        active: '#233353',
        focus: '#233353',
        disabled: '#C1C7D1'
      },
      secondary: {
        DEFAULT: '#1B65F5',
        hover: '#1B65F5',
        active: '#1B65F5',
        focus: '#1B65F5',
        disabled: '#C1C7D1'
      },
      tertiary: {
        DEFAULT: '#233353',
        hover: '#233353',
        active: '#233353',
        focus: '#233353',
        disabled: '#C1C7D1'
      },
      quaternary: {
        DEFAULT: '#233353',
        hover: '#233353',
        active: '#233353',
        focus: '#233353',
        disabled: '#C1C7D1'
      },
      link: {
        primary: {
          DEFAULT: '#233353',
          hover: '#053697',
          active: '#233353',
          focus: '#053697',
          disabled: '#C1C7D1'
        },
        secondary: {
          DEFAULT: '#1B65F5',
          hover: '#053697',
          active: '#233353',
          focus: '#053697',
          disabled: '#C1C7D1'
        }
      },
      text: {
        DEFAULT: '#1B65F5',
        hover: '#053697',
        active: '#233353',
        focus: '#053697',
        disabled: '#C1C7D1'
      }
    },

    input: {
      primary: {
        DEFAULT: '#727985',
        hover: '#727985',
        focus: '#233353',
        disabled: '#C1C7D1'
      },
      secondary: {
        DEFAULT: '#727985',
        hover: '#727985',
        focus: '#233353',
        disabled: '#C1C7D1'
      },
      tertiary: {
        DEFAULT: '#727985',
        hover: '#727985',
        focus: '#233353',
        disabled: '#C1C7D1'
      }
    },

    checkbox: {
      icon: '#fff'
    },

    icon: {
      primary: '#007AFF',
      secondary: '#233353',
      tertiary: '#716E6E',
      disabled: '#C1C7D1',
      error: '#FF3b30',
      success: '#1eb752',
      light: '#fff'
    },

    badge: {
      primary: {
        DEFAULT: '#233353'
      }
    },

    gradient: {
      primary: {
        first: 'rgba(249,227,176,0.3)',
        second: 'rgba(255,196,171,0.3)',
        third: 'rgba(236,180,208,0.3)',
        fourth: 'rgba(185,180,236,0.3)'
      },
      primary_solid: {
        first: 'rgba(249,227,176,1)',
        second: 'rgba(255,196,171,1)',
        third: 'rgba(236,180,208,1)',
        fourth: 'rgba(185,180,236,1)'
      },
      secondary: {
        first: '#F8F9FC',
        second: '#F1F7FF'
      },
      tertiary: {
        first: 'rgba(172,182,229,1)',
        second: 'rgba(116,235,213,1)'
      },
      quaternary: {
        first: 'transparent',
        second: '#F3F7FE'
      },
      fivefold: {
        first: 'rgba(255,255,255,0)',
        second: 'rgba(255,255,255,0.7)',
        third: 'rgba(255,255,255,1)'
      },
      sixfold: {
        first: 'rgba(255,255,255,0)',
        second: 'rgba(255,255,255,1)'
      }
    }
  },
  backgroundColor: {
    main: '#1B65F5',
    primary: '#FFF',
    secondary: '#F2F2F2',
    overlay: 'rgba(35,51,83,0.6)',
    error: '#FF3b30',
    success: '#32d74b',
    dark: '#000',
    light: '#fff',

    button: {
      primary: {
        DEFAULT: '#F7F9FF',
        hover: '#F2F5FF',
        active: 'var(--gradient-primary)',
        focus: '#F2F5FF',
        disabled: '#F2F2F2',
        loading: '#fff'
      },
      secondary: {
        DEFAULT: 'var(--gradient-secondary)',
        hover: '#FFF',
        active: '#FFF',
        focus: '#fff',
        disabled: '#F2F2F2',
        loading: '#fff'
      },
      tertiary: {
        DEFAULT: '#fff',
        hover: '#fff',
        active: '#fff',
        focus: '#fff',
        disabled: '#F2F2F2',
        loading: '#fff'
      },
      quaternary: {
        DEFAULT: '#fff',
        hover: '#fff',
        active: '#fff',
        focus: '#fff',
        disabled: '#F2F2F2',
        loading: '#fff'
      },
      link: {
        primary: {
          DEFAULT: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          focus: 'transparent',
          disabled: 'transparent'
        },
        secondary: {
          DEFAULT: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          focus: 'transparent',
          disabled: 'transparent'
        }
      },
      text: {
        DEFAULT: 'transparent',
        hover: 'transparent',
        active: 'transparent',
        focus: 'transparent',
        disabled: 'transparent'
      },
      icon: {
        DEFAULT: '#FFF',
        hover: '#F2F5FF',
        active: '#FFF',
        focus: '#F2F5FF',
        disabled: '#F2F2F2'
      }
    },

    input: {
      primary: {
        DEFAULT: '#F7F9FF',
        hover: '#F2F5FF',
        focus: '#FFFFFF',
        disabled: '#F2F2F2'
      },
      secondary: {
        DEFAULT: '#FFFFFF',
        hover: '#F2F5FF',
        focus: '#FFFFFF',
        disabled: '#F2F2F2'
      },
      tertiary: {
        DEFAULT: '#F7F9FF',
        hover: '#F2F5FF',
        focus: '#FFFFFF',
        disabled: '#F2F2F2'
      }
    },

    badge: {
      primary: {
        DEFAULT: '#FFFFFF'
      }
    },
    checkbox: {
      DEFAULT: '#1B65F5',
      disabled: '#727985'
    },
    calendar: {
      range: '#F5F7FF',
      active: '#1B65F5'
    },
    alert: {
      success: '#ddffe9',
      error: '#FFE7E6'
    }
  },
  borderColor: {
    primary: '#EFF3F8',
    secondary: '#EFF8F3',
    tertiary: '#185ada',
    error: '#FF3b30',
    success: '#32d74b',
    dark: '#000',
    light: '#fff',

    checkbox: {
      DEFAULT: '#1B65F5',
      disabled: '#727985'
    },
    alert: {
      success: '#ddffe9',
      error: '#FFE7E6'
    },

    button: {
      primary: {
        DEFAULT: '#EFF3F8',
        hover: '#EFF3F8',
        active: 'transparent',
        focus: '#1B65F5',
        disabled: '#F2F2F2',
        loading: 'transparent'
      },
      secondary: {
        DEFAULT: '#EFF3F8',
        hover: '#C4D6F9',
        active: '#1B65F5',
        focus: '#548BF8',
        disabled: '#F2F2F2',
        loading: 'transparent'
      },
      tertiary: {
        DEFAULT: 'transparent',
        hover: '#EFF3F8',
        active: 'transparent',
        focus: '#1B65F5',
        disabled: '#F2F2F2',
        loading: 'transparent'
      },
      quaternary: {
        DEFAULT: '#EFF3F8',
        hover: '#C4D6F9',
        active: '#EFF3F8',
        focus: '#1B65F5',
        disabled: '#F2F2F2',
        loading: 'transparent'
      },
      link: {
        primary: {
          DEFAULT: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          focus: 'transparent',
          disabled: 'transparent'
        },
        secondary: {
          DEFAULT: 'transparent',
          hover: 'transparent',
          active: 'transparent',
          focus: 'transparent',
          disabled: 'transparent'
        }
      },
      text: {
        DEFAULT: 'transparent',
        hover: 'transparent',
        active: 'transparent',
        focus: 'transparent',
        disabled: 'transparent'
      },
      icon: {
        DEFAULT: '#EFF3F8',
        hover: '#F2F5FF',
        active: '#EFF3F8',
        focus: '#F2F5FF',
        disabled: '#F2F2F2'
      }
    },

    input: {
      primary: {
        DEFAULT: '#EFF3F8',
        hover: '#EFF3F8',
        focus: '#EFF3F8',
        disabled: '#F2F2F2'
      },
      secondary: {
        DEFAULT: '#EFF3F8',
        hover: '#EFF3F8',
        focus: '#EFF3F8',
        disabled: '#F2F2F2'
      },
      tertiary: {
        DEFAULT: '#EFF3F8',
        hover: '#EFF3F8',
        focus: '#EFF3F8',
        disabled: '#F2F2F2'
      }
    }
  },
  boxShadow: {
    primary: '0 4px 4px 0px rgba(171,179,186,0.13)',
    secondary: '0 4px 12px 0px rgba(171,179,186,0.2)',
    tertiary: '0 8px 16px 0px rgba(47,53,77,0.12)',
    quaternary: '0 8px 20px 0px rgba(47,53,77,0.2)'
  },
  fontFamily: {
    sans: 'Inter',
    playfair: 'Playfair'
  }
}
