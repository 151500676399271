import { isSameDay } from 'date-fns'
import { computed, ref } from 'vue'
import { type LocationQueryRaw, useRoute } from 'vue-router'
import { formatDateForFilter } from '../lib'
import type { BaseSearchFilters } from '../model'
import { SearchSorting } from '../model'

export const usePageFilters = <T extends BaseSearchFilters>(
  pageSize = 5,
  changeHandler?: () => void
) => {
  const route = useRoute()

  /**
   * получает фильтры
   */
  const getFilters = (): T => {
    const base: any = {
      pagination: {
        pageSize: pageSize
      },
      filter: {}
    }
    const filtersFromRoute = getFiltersFromRoute()

    return {
      ...base,
      ...filtersFromRoute
    }
  }

  const getFiltersFromRoute = () => {
    //TODO: пока не прокидывется в query явно.
    // понять, нужно ли и реализовать
    if (!route.query) return {}

    const { fromDateTime, tillDateTime } = route.query
    if (!fromDateTime) return {}

    return {
      filter: {
        fromDateTime: fromDateTime as string,
        tillDateTime: tillDateTime as string
      }
    }
  }

  /**
   * явно апдейтит даты
   * @param value
   */
  const updateDateFilters = (value?: Date | Date[]) => {
    if (!value) {
      delete filters.value.filter.tillDateTime
      delete filters.value.filter.fromDateTime

      return onChange()
    }

    filters.value.filter!.fromDateTime = Array.isArray(value)
      ? formatDateForFilter(value[0])
      : formatDateForFilter(value)
    filters.value.filter!.tillDateTime = Array.isArray(value)
      ? formatDateForFilter(value[1])
      : formatDateForFilter(value)

    onChange()
  }

  /**
   * апдейт сотировки
   * @param value
   */
  const updateSorting = (value?: SearchSorting) => (filters.value.sorting = value) && onChange()
  //апдейт цен
  const updateFromPrice = (value: string | number) => {
    value || value === 0
      ? (filters.value.filter!.fromPrice! = Number(value))
      : delete filters.value.filter.fromPrice
    onChange()
  }
  const updateTillPrice = (value: string | number) => {
    value || value === 0
      ? (filters.value.filter!.tillPrice! = Number(value))
      : delete filters.value.filter.tillPrice
    onChange()
  }

  /**
   * вычисленное значения для календаря
   */
  const datepickerValue = computed(() => {
    if (!filters.value?.filter?.tillDateTime || !filters.value?.filter?.fromDateTime)
      return undefined
    const { tillDateTime, fromDateTime } = filters.value.filter

    if (isSameDay(tillDateTime!, fromDateTime)) return new Date(fromDateTime)

    return [new Date(fromDateTime), new Date(tillDateTime)]
  })

  /**
   * возвращает LocationQueryRaw со всеми предустановленными фильтрами
   */
  const getQueryFromFilters = (): LocationQueryRaw => {
    return {
      ...filters.value.filter
    }
  }

  /**
   * вызывает хэндлер при изменении фильтров
   */
  const onChange = () => changeHandler && changeHandler()

  const filters = ref<T | any>(getFilters())

  return {
    filters,
    datepickerValue,
    updateDateFilters,
    getQueryFromFilters,
    updateSorting,
    updateFromPrice,
    updateTillPrice
  }
}
